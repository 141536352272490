import { Col, Row } from 'antd';
import { NotificationType } from '@app/components/common/Notification/Notification';
import { CurrencyType } from '@app/interfaces/interfaces';
import { Priority } from '@app//constants/enums/priorities';
import { ReactComponent as ETHIcon } from '@app/assets/icons/eth.svg';
import { ReactComponent as BTCIcon } from '@app/assets/icons/btc.svg';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const getCurrencyPrice = (
  price: number | string,
  currency: CurrencyType,
  isIcon = true,
): string | React.ReactNode => {
  switch (currency) {
    case 'USD': {
      return isIcon ? `$${price}` : `${price} USD`;
    }

    case 'BTC': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <BTCIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} BTC`
      );
    }

    case 'ETH': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <ETHIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} ETH`
      );
    }

    default: {
      return isIcon ? `$${price}` : `${price} USD`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export const elementClasses = (...className: string[]): string => className.filter(Boolean).join(' ');

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertToAbbreviation = (input: string): string => {
  return input
    .split('_')
    .map((word) => word.charAt(0))
    .join('');
};

export const convertTypeParctice = (input: string): string => {
  const conversionMap: Record<string, string> = {
    LISTENING_FIB_L: 'Fill in the blanks [LFIB]',
    LISTENING_HIGHLIGHT_INCORRECT_WORD: 'Highlight incorrect words',
    LISTENING_MCQ_L_SINGLE_ANSWER: 'MC, choose single answer',
    LISTENING_SUMMARIZE_SPOKEN_TEXT: 'Summarize spoken text',
    LISTENING_DICTATION: 'Write from dictation [WFD]',
    LISTENING_SELECT_MISSING_WORD: 'Select missing words',
    LISTENING_HIGHLIGHT_CORRECT_SUMMARY: 'Highlight correct summary',
    LISTENING_MCQ_L_MULTIPLE_ANSWER: 'MC, choose multiple answers',
    SPEAKING_REPEAT_SENTENCE: 'Repeat sentence',
    SPEAKING_RETELL_LECTURE: 'Retell lecture',
    SPEAKING_DESCRIBE_IMAGE: 'Describe image',
    SPEAKING_READ_ALOUD: 'Read aloud',
    SPEAKING_ANSWER_SHORT_QUESTION: 'Answer short question',
    WRITING_ESSAY: 'Write essay [WE]',
    WRITING_SUMMARIZE_WRITTEN_TEXT: 'Summarize written text [SWT]',
    READING_FIB_R: 'R: Fill in the blanks [RFIB]',
    READING_FIB_R_W: 'R&W: Fill in the blanks [RWFIB]',
    READING_MCQ_R_MULTIPLE_ANSWER: 'MC, choose multiple answers',
    READING_MCQ_R_SINGLE_ANSWER: 'MC, choose single answer',
    READING_RE_ORDER_PARAGRAPH: 'Re-order paragraphs',
  };

  return conversionMap[input] || input;
};

export const convertQuestionByType = (input: string): string => {
  const conversionMap: Record<string, string> = {
    READING_FIB_R:
      'In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.',
    READING_FIB_R_W:
      'Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.',
    READING_MCQ_R_MULTIPLE_ANSWER:
      'Read the text and answer the question by selecting all the correct responses. You will need to select more than one response.',
    READING_MCQ_R_SINGLE_ANSWER:
      'Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.',
    READING_RE_ORDER_PARAGRAPH:
      'The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.',
    SPEAKING_REPEAT_SENTENCE:
      'You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.',
    SPEAKING_RETELL_LECTURE:
      'You will hear an Interview/Lecture. After listening to it, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.',
    SPEAKING_DESCRIBE_IMAGE:
      'Look at the graph below. In 25 seconds, please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.',
    SPEAKING_READ_ALOUD:
      'Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.',
    SPEAKING_ANSWER_SHORT_QUESTION:
      'You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.',
    WRITING_SUMMARIZE_WRITTEN_TEXT:
      'Read the passage below and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.',
    WRITING_ESSAY:
      'You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.',
    LISTENING_FIB_L: 'You will hear a recording. Type the missing words in each blank.',
    LISTENING_HIGHLIGHT_INCORRECT_WORD:
      'You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.',
    LISTENING_MCQ_L_SINGLE_ANSWER:
      'Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.',
    LISTENING_DICTATION:
      'You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.',
    LISTENING_SUMMARIZE_SPOKEN_TEXT:
      'You will hear a short audio. Write a summary for a fellow student who was not present at the Interview/Lecture. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the interview.',
    LISTENING_SELECT_MISSING_WORD:
      'You will hear a recording of a lecture. At the end of the recording the last word or group of words has been replaced by a beep. Select the correct option to complete the recording.',
    LISTENING_HIGHLIGHT_CORRECT_SUMMARY:
      'You will hear a recording. Click on the paragraph that best relates to the recording.',
    LISTENING_MCQ_L_MULTIPLE_ANSWER:
      'Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.',
  };

  return conversionMap[input] || input;
};

export const statusType: { [key: string]: string } = {
  Pending: 'warning',
  Done: 'success',
  Failed: 'error',
  Cancelled: 'default',
  NoAnswer: 'error',
};
