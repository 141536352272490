import { useEffect, useRef, useState } from 'react';
import { STAGES } from '@app/constants/stages';
import { Recording } from '../Recording';
import { Completed } from '../Completed';
import { Answer } from '../../Answer';
import { WaitingTime } from '../WaitingTime';

interface Props {
  waitingTime: number;
  recordingTime: number;
  showAnswer: boolean;
  text: string;
  questionNumber: string;
  onAnswerChange?: (audio: Blob) => void;
}

const SpeakingReadAloud: React.FC<Props> = ({
  waitingTime,
  recordingTime,
  showAnswer,
  text,
  questionNumber,
  onAnswerChange,
}) => {
  const recordingRef = useRef<{ stopRecording: () => void }>(null);
  const [stage, setStage] = useState(STAGES.START);
  const [audioURL, setAudioURL] = useState('');

  useEffect(() => {
    setStage(STAGES.COMPLETE);
    handleStopRecording();
    setStage(STAGES.START);
  }, [questionNumber]);

  useEffect(() => {
    if (!showAnswer) return;
    if (stage == STAGES.START) {
      setStage(STAGES.COMPLETE);
    } else {
      handleStopRecording();
    }
  }, [showAnswer]);

  const handleStart = () => {
    setStage(STAGES.RECORDING);
  };

  const handleComplete = async (audio: string) => {
    setStage(STAGES.COMPLETE);
    setAudioURL(audio);

    const blob = await fetch(audio).then((res) => res.blob());
    onAnswerChange && onAnswerChange(blob);
    // const blobURL = URL.createObjectURL(blob);
    // console.log(blobURL);
  };

  const handleStopRecording = () => {
    if (recordingRef.current) {
      recordingRef.current.stopRecording();
    }
  };

  return (
    <>
      {stage === STAGES.START && <WaitingTime onStart={handleStart} waitingTime={waitingTime} key={questionNumber} />}
      {stage === STAGES.RECORDING && (
        <Recording ref={recordingRef} recordingTime={recordingTime} onComplete={handleComplete} />
      )}

      {stage === STAGES.COMPLETE && <Completed />}

      <div className="mt-5">{text}</div>

      <div className="mt-5">
        {showAnswer && <Answer showAnswer={showAnswer} audioURL={audioURL} showNote={true} gradingContent={text} />}
      </div>
    </>
  );
};

export default SpeakingReadAloud;
