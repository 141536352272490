import { useState } from 'react';
import { Intro } from './Intro';
import { STEPS } from '@app/constants/stages';
import { CheckDevice } from './CheckDevice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Description } from './Description';
import { Note } from './Note';
import { notificationController } from '@app/controllers/notificationController';
import { IMockTestUserRequest, mockTestStart } from '@app/api/mockTest.api';

type StepConfig = {
  Component: React.FC<any>;
  props: Record<string, any>;
};

export const ExamMockTestIntro: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mockTestId = queryParams.get('id');
  const [step, setStep] = useState(STEPS.INTRO);

  const stepsConfig: Record<string, StepConfig> = {
    [STEPS.INTRO]: {
      Component: Intro,
      props: {
        gotoNext: () => setStep(STEPS.CHECK_DEVICE),
        gotoDashboard: () => navigate(`/`),
      },
    },
    [STEPS.CHECK_DEVICE]: {
      Component: CheckDevice,
      props: {
        gotoPrevious: () => setStep(STEPS.INTRO),
        gotoNext: () => setStep(STEPS.DESCRIPTION),
      },
    },
    [STEPS.DESCRIPTION]: {
      Component: Description,
      props: {
        gotoPrevious: () => setStep(STEPS.CHECK_DEVICE),
        gotoDashboard: () => navigate(`/`),
        gotoNext: () => setStep(STEPS.NOTE),
      },
    },
    [STEPS.NOTE]: {
      Component: Note,
      props: {
        gotoPrevious: () => setStep(STEPS.DESCRIPTION),
        gotoDashboard: () => navigate(`/`),
        gotoNext: () => {
          startMockTest();
        },
      },
    },
  };

  const startMockTest = async () => {
    try {
      if (!mockTestId) return;

      const payload: IMockTestUserRequest = { mockTestId };
      await mockTestStart(payload);
      navigate(`/exam/mock-test/detail?id=${mockTestId}`);
    } catch (error) {
      notificationController.error({
        message: 'Xảy ra lỗi. Vui lòng thử lại sau.',
      });
    }
  };

  return (
    <>
      {stepsConfig[step] &&
        (() => {
          const { Component, props } = stepsConfig[step];
          return <Component {...props} />;
        })()}
    </>
  );
};
