import { Button } from 'antd';
import { useState } from 'react';
import { Table } from 'components/common/Table/Table';
import * as S from './Intro.styles';

interface IntroProps {
  gotoNext: () => void;
  gotoDashboard: () => void;
}

export const Intro: React.FC<IntroProps> = ({ gotoNext, gotoDashboard }) => {
  const [columns, setColumns] = useState([
    {
      title: 'Part',
      dataIndex: 'part',
      render: (text: string, record: any, index: number) => {
        if (index === 3) {
          return {
            children: (
              <span className="block text-center">
                <strong>Optional break</strong> (Not included in Real Test)
              </span>
            ),
            props: {
              colSpan: 2,
            },
          };
        }
        return text;
      },
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: (text: string, record: any, index: number) => {
        if (index === 3) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return text;
      },
    },
    { title: 'Time allowed', dataIndex: 'time' },
  ]);
  const [data, setData] = useState([
    {
      key: 1,
      part: 'Intro',
      content: 'Introduction',
      time: 'No Time',
    },
    {
      key: 2,
      part: 'Part 1',
      content: 'Speaking and Writing',
      time: '54-67 minutes',
    },
    {
      key: 3,
      part: 'Part 2',
      content: 'Reading',
      time: '29-30 minutes',
    },
    {
      key: 4,
      part: 'Part 1',
      content: 'Speaking and Writing',
      time: '54-67 minutes',
    },
    {
      key: 5,
      part: 'Part 3',
      content: 'Listening',
      time: '30-43 minutes',
    },
  ]);
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>PTE Test - MAGIC Virtual Center Introduction</S.Title>
      <p>This introduction of mock test below is a summary. The Real Test will provide a longer introduction</p>

      <div className="my-2">
        <p className="font-bold">*Test Cautions:</p>
        <S.List>
          <li>The test measures English Reading, Writing, Listening, and Speaking skills for an academic setting.</li>
          <li>
            It's divided into three parts with individually timed sections. Instructions are provided at the beginning
            of each part.
          </li>
          <li>
            Users confirm answers and proceed to the next question by clicking [Next] button; however, they can't
            revisit previous questions.
          </li>
          <li>The test uses various English varieties (e.g., British, American, Australian).</li>
        </S.List>
      </div>
      <div className="my-2">
        <p className="font-bold">*Test Structure:</p>
        <p>
          The test is approximately 2 hours long, including 1 optional scheduled break (this is not included in the Real
          Test)
        </p>
      </div>
      <Table columns={columns} dataSource={data} loading={loading} scroll={{ x: 800 }} bordered pagination={false} />
      <S.BtnFooter>
        <Button type="default" onClick={gotoDashboard}>
          Return to Dashboard
        </Button>
        <Button type="primary" onClick={gotoNext}>
          Next
        </Button>
      </S.BtnFooter>
    </div>
  );
};
