import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import styled, { keyframes } from 'styled-components';

export const Title = styled(Typography.Title)`
  &.ant-typography {
    position: relative;
    color: var(--text-dark-color);
    font-size: ${FONT_SIZE.md};
    padding-left: 10px;

    &:before {
      content: '';
      width: 32px;
      height: 4px;
      padding-right: 8px;
      background-color: #fecc65;
      position: absolute;
      -webkit-transform: rotate(90deg) translate(20px, 25px);
      transform: rotate(90deg) translate(20px, 25px);
      border-radius: 12px;
    }

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.xxxxl};
    }
  }
`;

export const Audio = styled.div`
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
  margin: 24px 0;
`;

export const List = styled.ul`
  list-style-type: disc;
  padding-inline-start: 40px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 2rem;
`;

export const Column = styled.div`
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
`;

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

export const Box = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  height: 185px;
`;

export const Waveform = styled.div`
  width: 100%;
  max-width: 28rem;
  border-radius: 0.5rem;
  padding: 1rem;
  border-style: dashed;
  border-width: 2px;
  margin: 1rem auto;
`;
