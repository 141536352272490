import styled from 'styled-components';

export const Wrapper = styled.div`
  line-height: 2rem;
  border-radius: 0.5rem;
  background: #fff;
  padding: 2rem;
`;
export const Content = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: 600;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 2rem;
`;

export const Column = styled.div`
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
`;
