import React, { ChangeEvent, useEffect, useState } from 'react';
import * as S from './ListeningFIBL.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { STAGES } from '@app/constants/stages';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import { InfoModal } from '@app/components/common/Modal/Modal';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

interface Props {
  audioQuestion: string;
  prepTime: number;
  showAnswer: boolean;
  description: string;
  correctAnswersString: string;
  isFirstRender: React.MutableRefObject<boolean>;
  onAnswerChange?: (answer: string) => void;
}
export const ListeningFIBL: React.FC<Props> = ({
  prepTime,
  audioQuestion,
  showAnswer,
  description,
  correctAnswersString,
  isFirstRender,
  onAnswerChange,
}) => {
  const numberOfBlanks = (description.match(/@Blank@/g) || []).length;
  const [filledBlanks, setFilledBlanks] = useState<string[]>(new Array(numberOfBlanks).fill(''));

  // const [showAnswer, setShowAnswer] = useState(false);
  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  const { seconds, isRunning, start, pause, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    setFilledBlanks(new Array(numberOfBlanks).fill(''));
    if (isFirstRender.current) {
      isFirstRender.current = false;
      showPermissionModal();
    } else {
      initialize();
    }
  }, [audioQuestion]);

  const showPermissionModal = () => {
    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        initialize();
      },
    });
  };

  const initialize = () => {
    setIsPlaying(false);
    setStage(null);
    reset();

    setFilledBlanks(new Array(numberOfBlanks).fill(''));
    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }

    start();
    setStage(STAGES.START);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.COMPLETE);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedBlanks = [...filledBlanks];
    updatedBlanks[index] = e.target.value;
    setFilledBlanks(updatedBlanks);

    if (onAnswerChange) onAnswerChange(updatedBlanks.join(','));
  };

  const textWithBlanks = description.split(/@Blank@/).map((part, index) => {
    const filledBlank = filledBlanks[index] || '';
    const isBlank = index < filledBlanks.length;
    const isCorrect = filledBlank === correctAnswers[index];

    return (
      <React.Fragment key={`part-${index}`}>
        {part}
        {isBlank && (
          <S.InputStyle
            name={`input-${index}`}
            value={filledBlank}
            onChange={(e) => handleChange(e, index)}
            style={{
              borderColor: showAnswer ? (isCorrect ? 'green' : 'red') : '#000',
            }}
          />
        )}
        {showAnswer && !isCorrect && correctAnswers[index] && (
          <S.CorrectAnswer>({correctAnswers[index]})</S.CorrectAnswer>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      <S.WrapperAudio>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {stage === STAGES.PLAYING && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.WrapperAudio>
      <S.Wrapper>
        <S.ContenQuestion>{textWithBlanks}</S.ContenQuestion>
      </S.Wrapper>
    </>
  );
};
