import { useEffect, useState } from 'react';
import * as S from './WritingSummarizeWrittenText.styles';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { Answer } from '../../Answer';
import useDebounce from '@app/hooks/useDebounce';

interface Props {
  showAnswer: boolean;
  text: string;
  questionNumber: string;
  totalText: number;
  onAnswerChange?: (answer: string) => void;
}

export const WritingSummarizeWrittenText: React.FC<Props> = ({
  showAnswer,
  text,
  questionNumber,
  totalText,
  onAnswerChange,
}) => {
  const [answerContent, setAnswerContent] = useState<string>('');
  const debouncedAnswerContent = useDebounce(answerContent, 500);

  const wordCount = debouncedAnswerContent?.trim().split(/\s+/).filter(Boolean).length || 0;

  useEffect(() => {
    setAnswerContent('');
  }, [questionNumber]);

  useEffect(() => {
    if (onAnswerChange) onAnswerChange(debouncedAnswerContent);
  }, [debouncedAnswerContent, onAnswerChange]);

  return (
    <>
      <S.ContentQuestion>{text}</S.ContentQuestion>
      <TextArea
        rows={10}
        placeholder="Please write your answer here"
        value={answerContent}
        onChange={(e) => setAnswerContent(e.target.value)}
      />

      <div>
        Word count: <span style={{ color: wordCount > totalText ? 'red' : 'black' }}>{wordCount}</span>/{totalText}
      </div>

      <div className="mt-5">{showAnswer && <Answer />}</div>
    </>
  );
};
