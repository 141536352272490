/* eslint-disable react/no-unescaped-entities */
import * as S from './AnswerListeningDictation.styles';
import { Waveform } from '@app/components/exam/Waveform';
import { Button } from '@app/components/common/buttons/Button/Button';

interface ListeningDictationProps {
  audioURL: string;
  gradingContent: string;
}
export const AnswerListeningDictation: React.FC<ListeningDictationProps> = ({ audioURL, gradingContent }) => {
  return (
    <S.CardAnswer>
      <S.CardAnswerHead>
        <S.Title level={5}>Answer</S.Title>
      </S.CardAnswerHead>

      <S.Audio>
        <p className="text-sm font-semibold mb-4">System's Answer</p>
        {audioURL && <Waveform url={audioURL} />}
        <p className="text-sm font-semibold mt-4">{gradingContent}</p>

        <S.LevelList>
          <div className="flex items-center gap-2">
            <S.Good></S.Good>
            <span className="text-black">Good</span>
          </div>
          <div className="flex items-center gap-2">
            <S.Average></S.Average>
            <span className="text-black">Average</span>
          </div>
          <div className="flex items-center gap-2">
            <S.Missing></S.Missing>
            <span className="text-black">Missing</span>
          </div>
        </S.LevelList>
      </S.Audio>

      <S.CardAnswerBody>
        <S.Grading>Transcript</S.Grading>
        <S.Content>
          <S.TextRed>{gradingContent}</S.TextRed>
        </S.Content>
        <S.Grading>Grading</S.Grading>
        <S.Content>{gradingContent}</S.Content>
      </S.CardAnswerBody>
      <S.CardAnswerFooter></S.CardAnswerFooter>
    </S.CardAnswer>
  );
};
