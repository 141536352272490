import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #f8f9fa;
  color: #333;
  padding: 25px 20px;
  max-width: 72rem;
  margin: auto;
  margin-bottom: 110px;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  background: var(--ant-primary-color);
  padding: 30px;
  margin-right: 35px;
  z-index: 5;
`;

export const ToggleBtn = styled.div`
  position: fixed;
  top: calc(50% - 32px);
  right: 10px;
`;
