import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  color: #333;
  padding: 25px 20px;
  max-width: 72rem;
  margin: 2rem auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardHeaderLeft = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export const CardHeaderRight = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: max-content;
`;
