import { httpApi } from './http.api';

interface ISCORINGAICONTENT {
  data: any;
  statusCode: number;
  message: string;
}

export const scoringAIContent = (payload: any): Promise<ISCORINGAICONTENT> =>
  httpApi
    .post<ISCORINGAICONTENT>('api/speaking/content', payload, {
      baseURL: process.env.REACT_APP_AI_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
