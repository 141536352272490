import { useCallback, useEffect, useRef, useState } from 'react';
import * as S from './Exam.styles';
import { QuestionHeader } from './QuestionHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { DrawerListQuestion } from './DrawerListQuestion';
import { LeftArrowIcon } from '../common/icons/LeftArrowIcon';
import { RightArrowIcon } from '../common/icons/RightArrowIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { fetchExamDetail, fetchExamList, setPage } from '@app/store/slices/examSlice';
import { RenderQuestion } from './renderQuestion';

export const Exam: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loadingDetail, listExam, error, currentQuestion, currentIndex, page, totalPages, searchCode } = useSelector(
    (state: RootState) => state.exam,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const questionId = queryParams.get('id');
  const pageFromUrl = queryParams.get('page');

  const [open, setOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [again, setAgain] = useState(false);
  const [preBtnPrevious, setPreBtnPrevious] = useState(false);
  const isFirstRender = useRef(true);
  const [isLoadQuestion, setIsLoadQuestion] = useState(true);

  useEffect(() => {
    if (!type) return;

    const pageToUse = pageFromUrl ? parseInt(pageFromUrl) : page;
    if (pageToUse) {
      loadListQuestion(true);
      dispatch(fetchExamList({ page: pageToUse, type, searchCode }));
    }
  }, [pageFromUrl, dispatch]);

  useEffect(() => {
    if (listExam && listExam.length > 0 && isLoadQuestion) {
      retry();
      const targetQuestionId = preBtnPrevious ? listExam[listExam.length - 1].id : questionId || listExam[0].id;

      dispatch(fetchExamDetail(targetQuestionId));
      setPreBtnPrevious(false);
    }
  }, [listExam]);

  useEffect(() => {
    retry();
    if (!listExam.length || !questionId) return;
    const targetQuestionId = questionId;

    dispatch(fetchExamDetail(targetQuestionId));
    setPreBtnPrevious(false);
  }, [questionId]);

  const navigateToQuestion = (direction: 'next' | 'previous') => {
    const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    let newPage = page;

    if (newIndex < 0) {
      newPage = page - 1;
    } else if (newIndex >= listExam.length) {
      newPage = page + 1;
    }

    if (newPage !== page) {
      // dispatch(setPage(newPage));
      navigate(`/exam?type=${type}&page=${newPage}`);
      if (direction == 'previous') {
        setPreBtnPrevious(true);
      }
    } else if (newIndex >= 0 && newIndex < listExam.length) {
      navigate(`/exam?type=${type}&id=${listExam[newIndex].id}&page=${page}`);
    }
  };

  const handleNext = () => navigateToQuestion('next');

  const handlePrevious = () => navigateToQuestion('previous');

  const retry = () => {
    setShowAnswer(false);
    setAgain(false);
  };

  const handleTryAgain = () => {
    window.location.reload();
  };

  const handleAnswer = () => {
    setShowAnswer(true);
    setAgain(true);
  };

  const loadListQuestion = (flag: boolean) => {
    setIsLoadQuestion((prevState) => {
      return flag;
    });
  };

  if (error) return <p style={{ color: 'red' }}>{`Xảy ra lỗi. Vui lòng thử lại sau`}</p>;

  if (loadingDetail)
    return (
      <div className="flex items-center justify-center">
        <Spin />
      </div>
    );

  return (
    <>
      {currentQuestion && (
        <>
          <S.Wrapper>
            {/* Question Header */}
            <QuestionHeader
              orderId={currentQuestion?.orderId}
              difficulty={currentQuestion?.difficulty}
              type={type || ''}
            />
            {/* End Question Header */}

            {/* Question */}
            <div className="mt-5">
              {type && (
                <RenderQuestion
                  type={type}
                  currentQuestion={currentQuestion}
                  showAnswer={showAnswer}
                  isFirstRender={isFirstRender}
                />
              )}
            </div>
            {/* End Question */}
          </S.Wrapper>

          {/* Footer */}
          <S.Footer>
            <Button onClick={handlePrevious} disabled={currentIndex === 0 && page === 1}>
              Previous
            </Button>
            {!again ? (
              <Button type="default" onClick={handleAnswer}>
                Submit
              </Button>
            ) : (
              <Button type="default" onClick={handleTryAgain}>
                Try again
              </Button>
            )}

            <Button onClick={handleNext} disabled={currentIndex === listExam.length - 1 && page === totalPages}>
              Next
            </Button>
          </S.Footer>
          {/* End Footer */}

          <S.ToggleBtn>
            <Button
              type="primary"
              shape="circle"
              icon={open ? <RightArrowIcon /> : <LeftArrowIcon />}
              onClick={() => setOpen(true)}
              className="flex items-center justify-center"
            ></Button>
          </S.ToggleBtn>

          <DrawerListQuestion
            open={open}
            toggle={() => {
              setOpen(false);
            }}
            type={type || ''}
            loadListQuestion={loadListQuestion}
          />
        </>
      )}
    </>
  );
};
