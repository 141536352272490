import React from 'react';
import { elementClasses } from '../../../utils/utils';
import MockTestList from './mock-test-list';

export const MockTestPage: React.FC = () => {
  const tabs = [
    { name: 'All mock test', href: '#', current: true },
    { name: 'PTE Academic', href: '#', current: false },
    { name: 'PTE Core', href: '#', current: false },
  ];

  return (
    <div className="flex min-h-screen gap-10 bg-[#f9f9f9] px-8 pt-8">
      <div className="flex flex-1 flex-col gap-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <div className="shadow-xs flex flex-col rounded-lg bg-white p-8">
              <h1 className={elementClasses('text-lg font-semibold leading-8')}>Mock Test</h1>
              <label className="mt-5 text-sm font-medium text-gray-600" htmlFor="voucher">
                This AI practice test comprises of 4 modules in PTE Test: Speaking, Writing, Reading and Listening.
                There should be on average 70-75 questions and test timing is set in the analogous pattern as the actual
                test, which would help the candidates to know what to expect on the test day and check their skills
                level.
              </label>
              <div className="py-4  text-sm font-medium text-gray-600">
                <h3 className="mb-2 font-bold">Communicative scores:</h3>{' '}
                <div className="-mx-2 mb-4 flex flex-wrap">
                  <div className="w-1/2 px-2">
                    <ul className="list-disc pl-5">
                      <li>Speaking</li>
                      <li>Reading</li>
                    </ul>
                  </div>
                  <div className="w-1/2 px-2">
                    <ul className="list-disc pl-5">
                      <li>Writing</li>
                      <li>Listening</li>
                    </ul>
                  </div>
                </div>
                <h3 className="mb-2 font-bold">Enabling scores:</h3>
                <div className="-mx-2 flex flex-wrap">
                  <div className="w-1/2 px-2">
                    <ul className="list-disc pl-5">
                      <li>Grammar</li>
                      <li>Fluency</li>
                      <li>Written Discourse</li>
                    </ul>
                  </div>
                  <div className="w-1/2 px-2">
                    <ul className="list-disc pl-5">
                      <li>Pronunciation</li>
                      <li>Vocabulary</li>
                      <li>Spelling</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    defaultValue={tabs.find((tab) => tab.current)?.name ?? ''}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <a
                          key={tab.name}
                          href={tab.href}
                          className={elementClasses(
                            tab.current
                              ? 'border-[#ffc92b] text-black'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'w-full border-b-2 px-1 py-4 text-center text-base font-semibold',
                          )}
                          aria-current={tab.current ? 'page' : undefined}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <div className=" rounded-lg bg-white p-4">
                <MockTestList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
