import styled from 'styled-components';

export const Description = styled.div`
  text-align: left;
  font-size: 0.75rem;
  color: #000;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  max-height: 2rem;

  &::after {
    top: 1.5rem;
    right: 0;
    width: 35%;
    height: 1.5rem;
    content: '';
    position: absolute;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 75%);
    text-align: right;
  }
`;

export const TagNew = styled.span`
  font-size: 0.75rem;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: rgb(209, 54, 54);
`;
