import { Descriptions, Table, TableProps } from 'antd';
import { IDataTypeResult } from '..';
import * as S from './TableCollapse.styles';

interface TableCollapseProps<RecordType> {
  columns: TableProps<RecordType>['columns'];
  dataSource: RecordType[];
}

export const TableCollapse: React.FC<TableCollapseProps<IDataTypeResult>> = ({ columns, dataSource, ...rest }) => {
  return (
    <Table
      expandable={{
        expandedRowRender: (record) => {
          return (
            <S.Card>
              <S.Box>
                <b>Correct answer</b>
              </S.Box>
              <S.Box>
                The colonial institution has a lengthy and illustrious history that dates back over 200 years. Many of
                the persons who have worked here are scientists themselves, such as Albert Einstein. He produced
                breakthroughs that made it simpler to produce and use electricity, allowing us to do things like switch
                on lights, cook dinner, and play video games on consoles.
              </S.Box>
              <S.Box>
                <b>Your answer</b>
              </S.Box>
              <S.Box>
                <audio controls>
                  <source
                    src="https://storage.googleapis.com/pte-magic-question-2018/audio_intro_mock_test_1691121202670.mp3"
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </audio>
                The colonial institution has a lengthy and illustrious history that dates back over 200 years. Many of
                the persons who have worked here are scientists themselves, such as Albert Einstein. he produced
                breakthroughs that made it simpler to produce and use electricity, allowing us to do things like switch
                on lights, cook dinner, and play video games on consoles.
              </S.Box>
              <S.Box>
                <b>Score</b>
              </S.Box>
              <S.Box>
                <S.TableScore>
                  <S.ColScore>
                    <S.HeaderScore>Content</S.HeaderScore>
                    <p>2.92</p>
                  </S.ColScore>
                  <S.ColScore>
                    <S.HeaderScore>Pronunciation</S.HeaderScore>
                    <p>2.92</p>
                  </S.ColScore>
                  <S.ColScore>
                    <S.HeaderScore>Fluency</S.HeaderScore>
                    <p>2.92</p>
                  </S.ColScore>
                  <S.ColScore>
                    <S.HeaderScore>Total</S.HeaderScore>
                    <p>2.92</p>
                  </S.ColScore>
                </S.TableScore>
              </S.Box>
            </S.Card>
          );
        },
        rowExpandable: (record) => record.type !== 'Not Expandable',
        expandRowByClick: true,
        defaultExpandAllRows: false,
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      {...rest}
    />
  );
};
