import { useEffect, useRef, useState } from 'react';
import * as S from './SpeakingDescribeImage.styles';
import img from '@app/assets/images/SPEAKING_DESCRIBE_IMAGE.png';
import { STAGES } from '@app/constants/stages';
import { Recording } from '../Recording';
import { Completed } from '../Completed';
import { Answer } from '../../Answer';
import { WaitingTime } from '../WaitingTime';

interface Props {
  prepTime: number;
  recordingTime: number;
  showAnswer: boolean;
  imageUrl: string;
  questionNumber: string;
  onAnswerChange?: (audio: Blob) => void;
}

export const SpeakingDescribeImage: React.FC<Props> = ({
  prepTime,
  recordingTime,
  showAnswer,
  imageUrl,
  questionNumber,
  onAnswerChange,
}) => {
  const recordingRef = useRef<{ stopRecording: () => void }>(null);

  const [stage, setStage] = useState(STAGES.START);
  const [audioURL, setAudioURL] = useState('');

  useEffect(() => {
    handleStopRecording();
    setStage(STAGES.START);
  }, [questionNumber]);

  useEffect(() => {
    if (!showAnswer) return;
    if (stage == STAGES.START) {
      setStage(STAGES.COMPLETE);
    } else {
      handleStopRecording();
    }
  }, [showAnswer]);

  const handleStart = () => {
    setStage(STAGES.RECORDING);
  };

  const handleComplete = async (audio: string) => {
    setStage(STAGES.COMPLETE);
    setAudioURL(audio);

    const blob = await fetch(audio).then((res) => res.blob());
    onAnswerChange && onAnswerChange(blob);
  };

  const handleStopRecording = () => {
    if (recordingRef.current) {
      recordingRef.current.stopRecording();
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.Column>
          <img alt="Image" src={imageUrl} />
        </S.Column>
        <S.Column>
          {stage === STAGES.START && <WaitingTime onStart={handleStart} waitingTime={prepTime} />}
          {stage === STAGES.RECORDING && (
            <Recording ref={recordingRef} recordingTime={recordingTime} onComplete={handleComplete} />
          )}
          {stage === STAGES.COMPLETE && <Completed />}
        </S.Column>
      </S.Wrapper>
      <div className="mt-5">
        {showAnswer && (
          <Answer showAnswer={showAnswer} audioURL={audioURL} showNote={true} gradingContent="" transcript={true} />
        )}
      </div>
    </>
  );
};
