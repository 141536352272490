import { useEffect, useState } from 'react';
import { STAGES } from '@app/constants/stages';
import { InfoModal } from '@app/components/common/Modal/Modal';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import * as S from './ListeningHighlightIncorectWord.styles';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

interface Props {
  audioQuestion: string;
  prepTime: number;
  showAnswer: boolean;
  description: string;
  correctAnswersString: string;
  questionNumber: string;
  isFirstRender: React.MutableRefObject<boolean>;
  onAnswerChange?: (answer: string) => void;
}

export const ListeningHighlightIncorectWord: React.FC<Props> = ({
  audioQuestion,
  prepTime,
  showAnswer,
  description,
  correctAnswersString,
  questionNumber,
  isFirstRender,
  onAnswerChange,
}) => {
  const [selectedWords, setSelectedWords] = useState<{ word: string; index: number }[]>([]);

  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  const { seconds, start, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      showPermissionModal();
    } else {
      initialize();
    }
  }, [questionNumber]);

  useEffect(() => {
    onAnswerChange && onAnswerChange(selectedWords.map((selected) => selected.word).join(','));
  }, [selectedWords, onAnswerChange]);

  const showPermissionModal = () => {
    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        initialize();
      },
    });
  };

  const initialize = () => {
    setSelectedWords([]);
    setIsPlaying(false);
    setStage(null);
    reset();

    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }

    start();
    setStage(STAGES.START);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.COMPLETE);
  };

  const handleWordClick = (word: string, index: number) => {
    const isAlreadySelected = selectedWords.some((selected) => selected.index === index);

    if (isAlreadySelected) {
      setSelectedWords((prev) => prev.filter((selected) => selected.index !== index));
    } else {
      setSelectedWords((prev) => [...prev, { word, index }]);
    }
    // setShowAnswer(false);
  };

  const words = description
    .replace(/([,.])/g, ' $1')
    .split(' ')
    .map((word, index) => {
      const selectedWord = selectedWords.find((selected) => selected.index === index);
      const isSelected = selectedWord !== undefined;
      const isCorrect = correctAnswers.includes(word);

      let color = 'black';
      let borderColor = 'transparent';
      let backgroundColor = 'transparent';
      if (showAnswer) {
        if (isCorrect) {
          color = isSelected ? 'rgb(18, 211, 191)' : 'rgb(204, 204, 204)';
          borderColor = color;
          backgroundColor = isSelected ? 'rgb(240, 255, 251)' : 'transparent';
        } else if (isSelected) {
          color = 'rgb(255, 102, 102)';
          backgroundColor = 'rgb(255, 220, 220)';
          borderColor = color;
        }
      } else if (isSelected) {
        color = 'blue';
      }

      return (
        <span
          key={index}
          onClick={() => handleWordClick(word, index)}
          style={{
            cursor: 'pointer',
            color: color,
            border: `1px solid ${borderColor}`,
            background: backgroundColor,
            margin: '0 2px',
            borderRadius: '4px',
          }}
        >
          {word}{' '}
        </span>
      );
    });

  return (
    <>
      <S.WrapperAudio>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {stage === STAGES.PLAYING && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.WrapperAudio>
      <div className="mt-5">{words}</div>
    </>
  );
};
