import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as S from './ReadingFIBR.styles';

interface Props {
  showAnswer: boolean;
  text: string;
  initialChoicesString: string;
  correctAnswersString: string;
  onAnswerChange?: (answer: string) => void;
}

export const ReadingFIBR: React.FC<Props> = ({
  showAnswer,
  text,
  initialChoicesString,
  correctAnswersString,
  onAnswerChange,
}) => {
  const numberOfBlanks = (text.match(/@Blank@/g) || []).length;
  const [filledBlanks, setFilledBlanks] = useState<string[]>(new Array(numberOfBlanks).fill(''));

  const [initialChoices, setInitialChoices] = useState<{ label: string; text: string }[]>([]);
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  useEffect(() => {
    setFilledBlanks(new Array(numberOfBlanks).fill(''));
    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
      setInitialChoices(parsedChoices);
    }
  }, [initialChoicesString]);

  useEffect(() => {
    if (correctAnswersString) {
      const resultArray = correctAnswersString.split(',').map((item) => item.trim());
      setCorrectAnswers(resultArray);
    }
  }, [correctAnswersString]);

  const onDragStart = (e: React.DragEvent, index: number, type: 'choice' | 'blank') => {
    e.dataTransfer.setData('draggedItem', JSON.stringify({ index, type }));
  };

  const onDrop = (e: React.DragEvent, blankIndex: number) => {
    e.preventDefault();
    const draggedItem = JSON.parse(e.dataTransfer.getData('draggedItem'));

    const newChoices = [...choices];
    const newFilledBlanks = [...filledBlanks];

    // Xử lý trường hợp kéo lựa chọn vào ô trống
    if (draggedItem.type === 'choice') {
      const draggedChoice = newChoices[draggedItem.index];
      newChoices.splice(draggedItem.index, 1);

      // Nếu ô trống đã có giá trị, thêm giá trị cũ vào danh sách lựa chọn
      if (newFilledBlanks[blankIndex]) {
        const existingValue = newFilledBlanks[blankIndex];
        newChoices.push({ label: `choice-${uuidv4()}`, text: existingValue });
      }

      // Gán giá trị mới vào ô trống
      newFilledBlanks[blankIndex] = draggedChoice.text;
    }
    // Xử lý trường hợp kéo giữa các ô trống
    else if (draggedItem.type === 'blank') {
      const draggedBlankValue = newFilledBlanks[draggedItem.index];
      newFilledBlanks[draggedItem.index] = newFilledBlanks[blankIndex];
      newFilledBlanks[blankIndex] = draggedBlankValue;
    }

    setChoices(newChoices);
    setFilledBlanks(newFilledBlanks);
    onAnswerChange && onAnswerChange(newFilledBlanks.join(','));
  };

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const textWithBlanks = text.split(/@Blank@/).map((part, index) => {
    const filledBlank = filledBlanks[index] || '';
    const isBlank = index < filledBlanks.length;

    const foundChoice = initialChoices.find((choice) => filledBlank === choice.text);
    const filledLabel = foundChoice ? foundChoice.label : null;
    const isCorrect = filledLabel && correctAnswers[index] === filledLabel;

    return (
      <React.Fragment key={`part-${index}`}>
        {part}
        {isBlank && (
          <S.SmoothDND
            draggable
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, index)}
            onDragStart={(e) => onDragStart(e, index, 'blank')}
            style={{
              borderColor: showAnswer ? (isCorrect ? 'green' : 'red') : '#000',
              cursor: 'pointer',
            }}
          >
            {filledBlank || <span style={{ color: '#ccc' }}></span>}
          </S.SmoothDND>
        )}
        {showAnswer && !isCorrect && correctAnswers[index] && (
          <S.CorrectAnswer>
            ({initialChoices.find((choice) => choice.label === correctAnswers[index])?.text})
          </S.CorrectAnswer>
        )}
      </React.Fragment>
    );
  });

  return (
    <S.Wrapper>
      <S.ContenQuestion>{textWithBlanks}</S.ContenQuestion>

      <S.ListChoices>
        {choices.map((choice, index) => (
          <S.Choices
            key={choice.label}
            draggable
            onDragStart={(e) => onDragStart(e, index, 'choice')}
            style={{ cursor: 'move', padding: '5px', marginBottom: '10px', border: '1px solid #ccc' }}
          >
            {choice.text}
          </S.Choices>
        ))}
      </S.ListChoices>
    </S.Wrapper>
  );
};
