import { MOCK_TEST_STATUS, MOCK_TEST_USER_STATUS } from '@app/constants/mockTest';
import { IExam } from './academic.api';
import { httpApi } from './http.api';

export interface IDetailMockTest {
  questions: IExam[];
}

export interface IMockTest {
  id: string;
  name: string;
  description: string;
  status: MOCK_TEST_STATUS;
  year: number;
}

export interface IMockTestHis {
  id: string;
  name: string;
  status: MOCK_TEST_USER_STATUS;
  overallscore: number;
}

export interface IMockTestResponse {
  data: IMockTest[];
  meta: {
    itemCount: number;
    page: number;
    pageCount: number;
  };
}

export interface IMockTestHisResponse {
  mockTest: IMockTest;
  userMockTest: IMockTestHis[];
}

export interface IMockTestUserRequest {
  mockTestId: string;
}

export interface IMockTestUser {
  id: string;
  currentquestion: number;
  mocktestid: string;
  overallscore: number;
  remaintime: number;
  startdate: string;
  status: string;
  userid: string;
  step: string;
}
export interface IMockTestUserResponse {
  questions: IExam[];
  userMockTest: IMockTestUser;
}

export const getMockTestById = (): Promise<IDetailMockTest> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        questions: [
          {
            id: '6413235ef79715c8066b5447',
            audioLink:
              'https://storage.googleapis.com/pte-magic-materials/RA-Speaking-Advance-Feature/RA0284_1701402653290.mp3',
            text: "Nell and his colleagues took to the Everglades at night, hunting for gators near and far from nests.' You have to use a spotlight and you see the little demon eyes shining out of the marsh.' They lassoed the gators, pulled them into the airboat, and took blood samples and body measurements.",
            orderId: '1',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: '',
            imageUrl: '',
            topic: '',
            type: 'SPEAKING_READ_ALOUD',
          },
          {
            id: '2',
            audioLink:
              'https://storage.googleapis.com/pte-magic-materials/RA-Speaking-Advance-Feature/RA0284_1701402653290.mp3',
            text: "2 Nell and his colleagues took to the Everglades at night, hunting for gators near and far from nests.' You have to use a spotlight and you see the little demon eyes shining out of the marsh.' They lassoed the gators, pulled them into the airboat, and took blood samples and body measurements.",
            orderId: '2',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: '',
            imageUrl: '',
            topic: '',
            type: 'SPEAKING_READ_ALOUD',
          },
          {
            id: '3',
            audioLink:
              'https://storage.googleapis.com/pte-magic-materials/RA-Speaking-Advance-Feature/RA0284_1701402653290.mp3',
            text: "3 Nell and his colleagues took to the Everglades at night, hunting for gators near and far from nests.' You have to use a spotlight and you see the little demon eyes shining out of the marsh.' They lassoed the gators, pulled them into the airboat, and took blood samples and body measurements.",
            orderId: '3',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: '',
            imageUrl: '',
            topic: '',
            type: 'SPEAKING_READ_ALOUD',
          },
          {
            id: '4',
            audioLink: 'https://storage.googleapis.com/pte-magic-question-2018/question_20210523_1621750359081.mp3',
            text: '',
            orderId: 'RS0469',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: 'Presentation skills are important to both universities and workplaces.',
            imageUrl: '',
            topic: '',
            type: 'SPEAKING_REPEAT_SENTENCE',
          },
          {
            id: '5',
            audioLink: 'https://storage.googleapis.com/pte-magic-question-2018/question_20210710_1625841537871.png',
            text: '',
            orderId: 'DI0402',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: '',
            imageUrl:
              'https://storage.googleapis.com/pte-magic-question-2018/SPEAKING_DESCRIBE_IMAGE_1706334804887.png',
            topic: '',
            type: 'SPEAKING_DESCRIBE_IMAGE',
          },
          {
            id: '6',
            audioLink: 'https://storage.googleapis.com/pte-magic-question-2018/question_20211014_1634185758591.mp3',
            text: '',
            orderId: 'ASQ0481',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: 'Which one is past tense: has gone, went or going? Answer: went',
            imageUrl: '',
            topic: '',
            type: 'SPEAKING_ANSWER_SHORT_QUESTION',
          },
          {
            id: '7',
            audioLink: '',
            text: "The National Oceanography Center (NOC) is engaged in research into the potential risks and benefits of exploiting deep-sea mineral resources, some of which are essential for low-carbon technology, as well as using ocean robots to estimate the environmental impact of these potential deep-sea mining activities. Late last year the NOC led an expedition on the RRS James Cook that found enough of the scarce element Tellurium present in the crust of a submerged volcano that, if it were all to be used in the production of solar PV panels, could provide two-thirds of the UK's annual electricity supply. Recently, the NOC also led an international study demonstrating deep-sea nodule mining will cause long-lasting damage to deep-sea life, lasting at least for decades. ",
            orderId: 'SWT0176',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer: '',
            imageUrl: '',
            topic: '',
            type: 'WRITING_SUMMARIZE_WRITTEN_TEXT',
          },
          {
            id: '8',
            audioLink: '',
            text: 'The six gases: helium, neon, argon, krypton, xenon and radon have a myriad of modern uses. When they were first discovered, their strangely stand-offish properties were a mystery. Uniquely stable, they @Blank@ to participate in no chemical reactions. But by understanding the stability of the noble gases, physicists discovered the key to chemical bonding @Blank@ . Dmitri Mendeleev added the noble gases to his periodic table in 1902, where he arranged the elements in rows and columns @Blank@ to their atomic weight. Mendeleev was able to see repeating (or periodic) patterns in their properties. The noble gases appeared @Blank@ in the periodic table, occurring in every eighth position, at least amongst the lighter elements.',
            orderId: 'SWT0176',
            difficulty: '',
            description: '',
            choices:
              "[{label: 'A',text: 'seemed/refused/insisted/cared'},{label: 'B',text: 'itself/only/well/proper'},{label: 'C', text: 'disproportionate/opposite/additional/according'},{label: 'D', text: 'unevenly/regularly/vicariously/vaguely'}]",
            oldChoices: '',
            answer: 'seemed, itself, according, regularly',
            imageUrl: '',
            topic: '',
            type: 'READING_FIB_R_W',
          },
          {
            id: '9',
            audioLink: 'https://storage.googleapis.com/pte-magic-question-2018/question_20210715_1626273915019.mp3',
            text: '',
            orderId: 'SWT0176',
            difficulty: '',
            description: '',
            choices: '',
            oldChoices: '',
            answer:
              "Andrew Carnegie was born in Scotland in 1835. His family moved to America when he was 13 and settled in Pittsburgh. He found jobs working in a fabric mill and telegraph office. Carnegie always did well at whatever he set his mind to. He was known to make improvements to any job he held. While working for the telegraph office, he began studying the railroad system, and he soon became the superintendent. He took the money he had saved and invested in an oil company. His investment return was good and then he went to England to learn how to make steel. He wanted to make steel that was cheaper and more durable than American steel. He came back with his knowledge and built a huge steel factory. Carnegie's Factory was very successful and he sold it for 480 million dollars. Carnegie was a very wealthy man but he was more interested in philanthropy than spending his money on himself. He spent the rest of his life giving his money away to worthy causes. Reading and learning were very important to him, so he built many libraries. He also established universities and research institutes. Carnegie built hospitals, museums, and theaters. One of the most recognizable buildings he built was Carnegie Hall in New York City. Carnegie Hall hosts many concerts and symphonies each year and is an important part of New York City culture. Andrew Carnegie passed away at the age of 83. In his lifetime he gave away 90% of his fortune. He is known as a man who was selfless and wanted to do good for others.",
            imageUrl: '',
            topic: '',
            type: 'LISTENING_SUMMARIZE_SPOKEN_TEXT',
          },
        ],
      });
    });
  });
};

export const getMockTests = (page: number, take: number): Promise<IMockTestResponse> => {
  const queryString = `page=${page}&take=${take}`;
  return httpApi.get<IMockTestResponse>(`/mock-test?${queryString}`).then(({ data }) => data);
};

export const getMockTestsHis = (mockTestId: string): Promise<IMockTestHisResponse> => {
  const queryString = `mockTestId=${mockTestId}`;
  return httpApi.get<IMockTestHisResponse>(`/mock-test-user/history?${queryString}`).then(({ data }) => data);
};

export const mockTestResume = (mockTestId: string): Promise<IMockTestUserResponse> => {
  const queryString = `mockTestId=${mockTestId}`;
  return httpApi.get<IMockTestUserResponse>(`/mock-test-user/resume?${queryString}`).then(({ data }) => data);
};

export const mockTestStart = (payload: IMockTestUserRequest): Promise<any> =>
  httpApi.post<any>('/mock-test-user/start', { ...payload }).then(({ data }) => data);

export const mockTestCancel = (payload: IMockTestUserRequest): Promise<any> =>
  httpApi.post<any>('/mock-test-user/reset', { ...payload }).then(({ data }) => data);

export const saveProgressMockTest = (payload: FormData) =>
  httpApi.post<any>('/mock-test-user/answer-submit', payload).then(({ data }) => data);

export const mockTestFinish = (payload: IMockTestUserRequest): Promise<any> =>
  httpApi.post<any>('/mock-test-user/finish', { ...payload }).then(({ data }) => data);
