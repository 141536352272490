import React from 'react';
import { MOCK_TEST_STATUS } from '@app/constants/mockTest';
import * as S from './MockTest.styles';

export interface MockTestProps {
  title: string;
  description: string;
  status: string;
  year: number;
  duration: string;
  onClick: () => void;
}

const MockTest: React.FC<MockTestProps> = ({ title, description, status, year, duration, onClick }) => {
  const statusStyles: { [key: string]: string } = {
    IN_PROGRESS: 'bg-blue-500',
    NOT_YET_TAKEN: 'bg-gray-400',
    ATTEMPTED: 'bg-green-500',
  };

  const statusString = MOCK_TEST_STATUS[status as MOCK_TEST_STATUS];

  return (
    <button
      className="mb-4 flex cursor-pointer flex-col rounded-sm border border-solid border-[#333] p-4 shadow-md"
      onClick={onClick}
      onKeyDown={(event) => event.key === 'Enter' && onClick()}
    >
      <span
        className={`
        mb-2 w-fit rounded-3xl px-2 py-1 text-xs font-semibold text-white
         ${statusStyles[status]}`}
      >
        {statusString}
      </span>
      <h3 className="text-lg font-semibold">{title}</h3>
      <S.Description>{description}</S.Description>
      <div className="mt-2 flex space-x-2">
        <S.TagNew>{`NEW`}</S.TagNew>
        <span className="rounded bg-indigo-500 px-2 py-1 text-xs text-white">{year}</span>
        <span className="rounded bg-indigo-500 px-2 py-1 text-xs text-white">{duration}</span>
      </div>
    </button>
  );
};

export default MockTest;
