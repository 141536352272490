'use client';

import { useEffect, useState } from 'react';

import MockTest from './mock-test';
import { useNavigate } from 'react-router-dom';
import { getMockTests, IMockTest } from '@app/api/mockTest.api';
import { Pagination } from '@app/components/common/Pagination/Pagination';
import { notificationController } from '@app/controllers/notificationController';

const MockTestList = () => {
  const navigate = useNavigate();
  const [mockTests, setMockTests] = useState<IMockTest[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    const fetchMockTest = async () => {
      try {
        const res = await getMockTests(currentPage, pageSize);
        setMockTests(res.data);
        setTotalData(res.meta.itemCount);
      } catch (error) {
        console.log(error);
        notificationController.error({
          message: 'Xảy ra lỗi. Vui lòng thử lại sau.',
        });
      }
    };

    fetchMockTest();
  }, [currentPage, pageSize]);

  const handleTestClick = (id: string) => {
    console.log(`Test with id ${id} clicked`);
    navigate(`/exam/mock-test?id=${id}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="w-full max-w-4xl" style={{ minHeight: '200px' }}>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {mockTests.map((test, index) => (
              <MockTest
                key={index}
                title={test.name}
                status={test.status}
                year={test.year}
                description={test.description}
                duration={'2-HOUR'}
                onClick={() => handleTestClick(test.id)}
              />
            ))}
          </div>
          <Pagination
            className="text-center"
            showSizeChanger={false}
            defaultCurrent={currentPage}
            total={totalData}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default MockTestList;
