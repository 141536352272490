import { httpApi } from '@app/api/http.api';
import { number } from 'echarts';
// export interface IQuestion {
//     description: string;
//     imageUrl: string;
//     name: string;
//     numberTested: number;
//     remainAttemps: number;
//     skillSet: string;
//     total: number;
//     type: string;
// }

export interface IQuestionByType {
  type: string;
  total: number;
}

export interface IQuestion {
  type: string;
  total: number;
}

export interface IExam {
  id: string;
  audioLink: string;
  text: string;
  orderId: string;
  difficulty: string;
  description: string;
  choices: string;
  oldChoices: string;
  answer: string;
  imageUrl: string;
  topic: string;
  type: string;
}

export interface IExamList {
  data: IExam[];
  meta: {
    itemCount: number;
    page: number;
    pageCount: number;
  };
}

export const getQuestionsByType = (type: string, categories: string[]): Promise<IQuestionByType[]> => {
  const queryString = `questionType=${encodeURIComponent(type)}&${categories
    .map((category) => `questionSubType=${encodeURIComponent(category)}`)
    .join('&')}`;

  return httpApi.get<IQuestionByType[]>(`/questions/grouped-by-type?${queryString}`).then(({ data }) => data);
};

export const getExams = (page: number, take: number, questionSubType: string, search: string): Promise<IExamList> => {
  const queryString = `order=ASC&page=${page}&take=${take}&questionSubType=${encodeURIComponent(
    questionSubType,
  )}&search=${encodeURIComponent(search)}`;

  return httpApi.get<IExamList>(`/questions?${queryString}`).then(({ data }) => data);
};

export const getDetailExam = (id: string): Promise<IExam> => {
  return httpApi.get<IExam>(`/questions/${id}`).then(({ data }) => data);
};
