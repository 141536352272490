import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ExamMockTestFinish.styles';
import { ReactComponent as FinishIcon } from '@app/assets/icons/finish.svg';

export const ExamMockTestFinish: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mockTestId = queryParams.get('id');

  const handlGoToMockTest = () => {
    navigate(`/exam/mock-test?id=${mockTestId}`);
  };
  return (
    <>
      <S.Header>
        <p className="text-base">Finish exam</p>
      </S.Header>
      <S.Wrapper>
        <FinishIcon />
        <p className="text-2xl font-bold">Great! You have already completed the exam!</p>
        <p className="text-base">
          Are you interested in obtaining your test score to gauge your performance?
          <b>Please note, there will be a deduction of 1 Scoring Token if you choose to score it now!</b>
        </p>
        <div className="flex gap-2">
          <Button type="primary">Score</Button>
          <Button type="default" onClick={handlGoToMockTest}>
            Return to mock test
          </Button>
        </div>
      </S.Wrapper>
    </>
  );
};
