import { Button } from 'antd';
import { useState } from 'react';
import * as S from './Note.styles';
import { Modal } from '@app/components/common/Modal/Modal';

interface NoteProps {
  gotoPrevious: () => void;
  gotoNext: () => void;
  gotoDashboard: () => void;
}

export const Note: React.FC<NoteProps> = ({ gotoPrevious, gotoDashboard, gotoNext }) => {
  const [isModalStart, setIsModalStart] = useState<boolean>(false);

  const handleStartClick = () => {
    gotoNext();
  };
  return (
    <>
      <S.Wrapper>
        <S.Content>
          <p>You are about to begin Part 1 of the exam : Speaking</p>
          <p>Time allowed : 54 - 67 minutes</p>
          <p>Remember : to put your headphones on before beginning this section</p>
        </S.Content>

        <S.Footer>
          <S.Column>
            <Button type="default" onClick={gotoDashboard}>
              Return to Dashboard
            </Button>
          </S.Column>
          <S.Column className="flex justify-end gap-3">
            <Button type="default" onClick={gotoPrevious}>
              Previous
            </Button>
            <Button type="primary" onClick={() => setIsModalStart(true)}>
              Next
            </Button>
          </S.Column>
        </S.Footer>
      </S.Wrapper>
      <Modal
        title={`Start a new exam`}
        visible={isModalStart}
        onOk={handleStartClick}
        onCancel={() => setIsModalStart(false)}
      >
        {/* <p>MOCK 13 - 2 HOURS</p> */}
        <p>{`Are you sure to start new Mock Test?`}</p>
      </Modal>
    </>
  );
};
