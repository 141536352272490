import React from 'react';
import { QUESTIONS } from '@app/constants/questions';
import SpeakingReadAloud from './Speaking/Question/SpeakingReadAloud';
import { SpeakingRepeatSentence } from './Speaking/Question/SpeakingRepeatSentence';
import { IExam } from '@app/api/academic.api';
import { SpeakingDescribeImage } from './Speaking/Question/SpeakingDescribeImage';
import { SpeakingRetellLecture } from './Speaking/Question/SpeakingRetellLecture';
import { WritingSummarizeWrittenText } from './Writing/Question/WritingSummarizeWrittenText';
import { ReadingMCQRSingleAnswer } from './Reading/Question/ReadingMCQRSingleAnswer';
import { ReadingMCQRMultipleAnswer } from './Reading/Question/ReadingMCQRMultipleAnswer';
import { ReadingFIBR } from './Reading/Question/ReadingFIBR';
import { ReadingFIBRW } from './Reading/Question/ReadingFIBRW';
import { ReadingReOrderParagraph } from './Reading/Question/ReadingReOrderParagraph';
import { ListeningDictation } from './Listening/Question/ListeningDictation';
import { ListeningMCQRSingleAnswer } from './Listening/Question/ListeningMCQRSingleAnswer';
import { ListeningFIBL } from './Listening/Question/ListeningFIBL';
import { ListeningMCQLMultipleAnswer } from './Listening/Question/ListeningMCQLMultipleAnswer';
import { ListeningSummarizeSpokenText } from './Listening/Question/ListeningSummarizeSpokenText';
import { ListeningHighlightIncorectWord } from './Listening/Question/ListeningHighlightIncorectWord';

export interface RenderQuestionProps {
  type: string;
  currentQuestion: IExam | null;
  showAnswer: boolean;
  isFirstRender: React.MutableRefObject<boolean>;
  onAnswerChange?: (answer: string | Blob) => void;
}

export const RenderQuestion: React.FC<RenderQuestionProps> = React.memo(
  ({ type, currentQuestion, showAnswer, isFirstRender, onAnswerChange }) => {
    if (!currentQuestion) return null;
    const { SPEAKING, WRITING, READING, LISTENING } = QUESTIONS;

    switch (type) {
      case SPEAKING.READ_ALOUD:
        return (
          <SpeakingReadAloud
            waitingTime={40}
            recordingTime={40}
            showAnswer={showAnswer}
            text={currentQuestion.text}
            questionNumber={currentQuestion.id}
            onAnswerChange={onAnswerChange}
          />
        );
      case SPEAKING.REPEAT_SENTENCE:
        return (
          <SpeakingRepeatSentence
            prepTime={5}
            recordingTime={10}
            audioQuestion={currentQuestion?.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            gradingContent={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case SPEAKING.DESCRIBE_IMAGE:
        return (
          <SpeakingDescribeImage
            prepTime={25}
            recordingTime={40}
            showAnswer={showAnswer}
            imageUrl={currentQuestion.imageUrl}
            questionNumber={currentQuestion.id}
            onAnswerChange={onAnswerChange}
          />
        );
      case SPEAKING.RETELL_LECTURE:
        return (
          <SpeakingRetellLecture
            prepTime={5}
            recordingTime={40}
            waitingTime={10}
            audioQuestion={currentQuestion?.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            gradingContent={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case SPEAKING.ANSWER_SHORT_QUESTION:
        return (
          <SpeakingRetellLecture
            prepTime={3}
            recordingTime={10}
            waitingTime={3}
            audioQuestion={currentQuestion?.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            gradingContent={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case WRITING.SUMMARIZE_WRITTEN_TEXT:
        return (
          <WritingSummarizeWrittenText
            showAnswer={showAnswer}
            text={currentQuestion.text}
            questionNumber={currentQuestion.id}
            totalText={75}
            onAnswerChange={onAnswerChange}
          />
        );
      case WRITING.WRITING_ESSAY:
        return (
          <WritingSummarizeWrittenText
            showAnswer={showAnswer}
            text={currentQuestion.text}
            questionNumber={currentQuestion.id}
            totalText={300}
            onAnswerChange={onAnswerChange}
          />
        );
      case READING.MCQ_R_SINGLE_ANSWER:
        return (
          <ReadingMCQRSingleAnswer
            showAnswer={showAnswer}
            text={currentQuestion.text}
            description={currentQuestion.description}
            initialChoicesString={currentQuestion.oldChoices}
            answer={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case READING.MCQ_R_MULTIPLE_ANSWER:
        return (
          <ReadingMCQRMultipleAnswer
            showAnswer={showAnswer}
            text={currentQuestion.text}
            description={currentQuestion.description}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case READING.FIB_R:
        return (
          <ReadingFIBR
            showAnswer={showAnswer}
            text={currentQuestion.text}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case READING.FIB_R_W:
        return (
          <ReadingFIBRW
            showAnswer={showAnswer}
            text={currentQuestion.text}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case READING.RE_ORDER_PARAGRAPH:
        return (
          <ReadingReOrderParagraph
            showAnswer={showAnswer}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case LISTENING.DICTATION:
        return (
          <ListeningDictation
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            audioAnswer={currentQuestion.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            gradingContent={currentQuestion.answer}
            onAnswerChange={onAnswerChange}
          />
        );
      case LISTENING.MCQ_L_SINGLE_ANSWER:
      case LISTENING.HIGHLIGHT_CORRECT_SUMMARY:
      case LISTENING.SELECT_MISSING_WORD:
        return (
          <ListeningMCQRSingleAnswer
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            isFirstRender={isFirstRender}
            onAnswerChange={onAnswerChange}
          />
        );
      case LISTENING.FIB_L:
        return (
          <ListeningFIBL
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            description={currentQuestion.description}
            correctAnswersString={currentQuestion.answer}
            isFirstRender={isFirstRender}
            onAnswerChange={onAnswerChange}
          />
        );
      case LISTENING.MCQ_L_MULTIPLE_ANSWER:
        return (
          <ListeningMCQLMultipleAnswer
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            initialChoicesString={currentQuestion.oldChoices}
            correctAnswersString={currentQuestion.answer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            onAnswerChange={onAnswerChange}
          />
        );

      case LISTENING.SUMMARIZE_SPOKEN_TEXT:
        return (
          <ListeningSummarizeSpokenText
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            onAnswerChange={onAnswerChange}
          />
        );
      case LISTENING.HIGHLIGHT_INCORRECT_WORD:
        return (
          <ListeningHighlightIncorectWord
            prepTime={5}
            audioQuestion={currentQuestion.audioLink}
            showAnswer={showAnswer}
            description={currentQuestion.description}
            correctAnswersString={currentQuestion.answer}
            questionNumber={currentQuestion.id}
            isFirstRender={isFirstRender}
            onAnswerChange={onAnswerChange}
          />
        );
      default:
        return null;
    }
  },

  (prevProps, nextProps) => {
    return (
      prevProps.type === nextProps.type &&
      prevProps.currentQuestion === nextProps.currentQuestion &&
      prevProps.showAnswer === nextProps.showAnswer &&
      prevProps.isFirstRender === nextProps.isFirstRender
    );
  },
);
