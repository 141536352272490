import { IQuestion } from '@app/api/academic.api';
import * as S from './BoxSection.styles';
import { ReactComponent as DotIcon } from '@app/assets/icons/dot-icon.svg';
import { convertToAbbreviation, convertTypeParctice } from '@app/utils/utils';

interface BoxSectionProps {
  data: IQuestion;
  section: string;
}

export const BoxSection: React.FC<BoxSectionProps> = ({ data, section }) => {
  const url = () => {
    return `/exam?type=${data.type}&page=1`;
  };

  return (
    <S.Box>
      <a href={url()} className="text-black">
        <div className="px-2 py-4">
          <div className="flex items-center gap-2">
            <S.BoxType>{convertToAbbreviation(data.type)}</S.BoxType>
            <p className="uppercase">{section}</p>
          </div>
          <S.BoxTitle>{convertTypeParctice(data.type)}</S.BoxTitle>
          <div className="flex items-center gap-2">
            <div className="flex">
              <DotIcon />
              <DotIcon />
            </div>
            {/* <span>{data.numberTested}</span> */}
            <span>{data.total}</span>
          </div>
        </div>
        <div className="flex justify-end">
          <S.BoxTag>Download</S.BoxTag>
        </div>
      </a>
    </S.Box>
  );
};
