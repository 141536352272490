export const STAGES = {
  START: 'start',
  WAITING: 'waiting',
  PLAYING: 'playing',
  RECORDING: 'recording',
  COMPLETE: 'complete',
};

export const STEPS = {
  INTRO: 'intro',
  CHECK_DEVICE: 'checkDevice',
  DESCRIPTION: 'description',
  NOTE: 'note',
};
