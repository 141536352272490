import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  margin-bottom: 24px;
`;

export const SmoothDND = styled.div`
  min-width: 140px;
  min-height: 35px;
  padding: 0;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #000;
  line-height: 35px;
  height: 35px;
  text-align: center;
  margin: -12px 8px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ContenQuestion = styled.div`
  padding: 20px;
  line-height: 40px;
`;

export const ListChoices = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
export const Choices = styled.div`
  padding: 10px;
  margin: 0 10px;
  background: #e0e0e0;
  border-radius: 4px;
  cursor: grab;
`;

export const CorrectAnswer = styled.span`
  color: green;
  margin-left: 5px;
  font-weight: 600;
`;
