import { Tabs, TabPane } from '@app/components/common/Tabs/Tabs';
import * as S from './ResultDetailPage.styles';
import { useState } from 'react';
import { TableCollapse } from '@app/components/common/TableCollapse';
import { Tag, TableProps } from 'antd';
import { CopyFilled, EyeOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { IDataType } from '@app/interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import { statusType } from '@app/utils/utils';

export const ResultDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<TableProps<IDataType>['columns']>([
    {
      title: 'Id',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={statusType[status]} key={status}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Total Score',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, record: IDataType) => (
        <div className="flex gap-2">
          <Button type="primary" size="small" icon={<EyeOutlined />} onClick={() => handleViewDetails(record.key)} />
          <Button type="default" size="small" icon={<CopyFilled />} />
        </div>
      ),
    },
  ]);
  const [data, setData] = useState<IDataType[]>([
    {
      key: '1',
      name: 'John Doe',
      total: 100,
      type: 'Speaking',
      question: 'What is 2+2?',
      answer: '4',
      marks: 5,
      status: 'Pending',
    },
    {
      key: '2',
      name: 'Jane Smith',
      total: 100,
      type: 'Speaking',
      question: 'What is the capital of France?',
      answer: 'Paris',
      marks: 10,
      status: 'Done',
    },
  ]);

  const handleViewDetails = (id: string) => {
    navigate(`/mock-test/${id}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Detail Test</S.Title>

      <Tabs defaultActiveKey="1">
        <TabPane tab={`Speaking`} key="1">
          <TableCollapse columns={columns} dataSource={data} />
        </TabPane>
        <TabPane tab={`Writing`} key="2">
          <TableCollapse columns={columns} dataSource={data} />
        </TabPane>
        <TabPane tab={`Reading`} key="3">
          3
        </TabPane>
        <TabPane tab={`Listening`} key="4">
          4
        </TabPane>
      </Tabs>
    </div>
  );
};
