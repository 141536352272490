import { useRef, useState } from 'react';
import { Button } from 'antd';
import * as S from './CheckDevice.styles';
import { Checkbox, CheckboxGroup, CheckboxChangeEvent } from '@app/components/common/Checkbox/Checkbox';
import { WaitingTime } from '@app/components/exam/Speaking/Question/WaitingTime';
import { Recording } from '@app/components/exam/Speaking/Question/Recording';
import { Completed } from '@app/components/exam/Speaking/Question/Completed';
import { STAGES } from '@app/constants/stages';
import { Waveform } from '@app/components/exam/Waveform';
import { useNavigate } from 'react-router-dom';

interface CheckDeviceProps {
  gotoPrevious: () => void;
  gotoNext: () => void;
}

export const CheckDevice: React.FC<CheckDeviceProps> = ({ gotoPrevious, gotoNext }) => {
  const navigate = useNavigate();
  const recordingRef = useRef<{ stopRecording: () => void }>(null);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [stage, setStage] = useState(STAGES.START);
  const [audioURL, setAudioURL] = useState('');

  const handleStart = () => {
    setStage(STAGES.RECORDING);
  };

  const handleComplete = (audio: string) => {
    setStage(STAGES.COMPLETE);
    setAudioURL(audio);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  // const handleStartTest = () => {
  //   console.log(checked);
  //   if (!checked) return;
  //   navigate(`/exam/mock-test/detail`);
  // };

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Equipment preparation</S.Title>
      <p className="font-semibold text-xl mb-1">*Headset Check</p>
      <p>
        Please click [Play] on the short recording below, and make sure that you can hear the recording clearly. If not,
        please do Device Test or Contact Support Centre
      </p>
      <S.Audio>
        <audio controls className="w-full">
          <source
            src="https://storage.googleapis.com/pte-magic-question-2018/audio_intro_mock_test_1691121202670.mp3"
            type="audio/mpeg"
          />
          Your browser does not support the audio element.
        </audio>
      </S.Audio>
      <p className="font-semibold text-xl mb-1">*Microphone Check</p>
      <p>
        Please click [Start] and say “Testing, testing, one, two, three” into the microphone, Click [Stop] after finish
        record. After that, click [Playback] to check that you can hear your voice clearly. If not, please do Device
        Test or Contact Support Centre
      </p>
      <S.Box>
        {stage === STAGES.START && <WaitingTime onStart={handleStart} waitingTime={5} />}
        {stage === STAGES.RECORDING && <Recording ref={recordingRef} recordingTime={5} onComplete={handleComplete} />}
        {stage === STAGES.COMPLETE && <Completed />}
        {audioURL && (
          <S.Waveform>
            <Waveform url={audioURL} />
          </S.Waveform>
        )}
      </S.Box>
      <p className="font-semibold text-xl mb-1">*Note</p>
      <S.List>
        <li>
          During the practice you will not have [Play], [Stop] and [Record] buttons. The audio and voice recording will
          start playing automatically.
        </li>
        <li>Please do not remove your headset and microphone. You should wear it during the test.</li>
      </S.List>

      <S.Footer>
        <S.Column>
          {/* <Checkbox checked={checked} disabled={stage != STAGES.COMPLETE} onChange={onChange}> */}
          <Checkbox checked={checked} onChange={onChange}>
            I confirm that my headset & microphone work good
          </Checkbox>
        </S.Column>
        <S.Column className="flex justify-end gap-3">
          <Button type="default" onClick={gotoPrevious}>
            Previous
          </Button>
          <Button type="primary" disabled={!checked} onClick={gotoNext}>
            Next
          </Button>
        </S.Column>
      </S.Footer>
    </div>
  );
};
