export enum MOCK_TEST_STATUS {
  ATTEMPTED = 'ATTEMPTED',
  NOT_YET_TAKEN = 'NOT_YET_TAKEN',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum MOCK_TEST_USER_STATUS {
  DONE = 'DONE',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
}
