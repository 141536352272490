import { useEffect, useState } from 'react';
import { Button, Tag } from 'antd';
import { ReactComponent as MockIcon } from '@app/assets/icons/mock.svg';
import { IMockTestHis } from '@app/api/mockTest.api';
import { Table } from 'components/common/Table/Table';
import { MOCK_TEST_USER_STATUS } from '@app/constants/mockTest';

export interface MockTestProps {
  mockTestHis: IMockTestHis[];
  onContinue: () => void;
  onResult: () => void;
}

export const ExamMockTestTable: React.FC<MockTestProps> = ({ mockTestHis, onContinue, onResult }) => {
  const [columns, setColumns] = useState([
    {
      title: 'Mock Name',
      dataIndex: 'name',
      render: (mock_name: string) => (
        <div className="flex gap-3 items-center">
          <MockIcon />
          <span className="font-bold text-base uppercase">{mock_name}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        const statusColors: Record<string, string> = {
          Done: 'success',
          IN_PROGRESS: 'processing',
          CANCELLED: 'error',
          FINISHED: 'warning',
        };

        return (
          <Tag color={statusColors[status] || 'default'} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'overallscore',
      render: (overallscore: number, record: IMockTestHis) => {
        if (record.status === MOCK_TEST_USER_STATUS.DONE) {
          return overallscore;
        }
        return '--';
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'startdate',
      render: (startdate: string) => {
        return new Intl.DateTimeFormat('en-GB').format(new Date(startdate));
      },
    },
    {
      title: 'View',
      dataIndex: 'status',
      render: (status: string) => {
        if (status === MOCK_TEST_USER_STATUS.DONE) {
          return (
            <div className="flex gap-2">
              <Button size="small">Result</Button>
              <Button size="small">Report</Button>
            </div>
          );
        }
        if (status === MOCK_TEST_USER_STATUS.IN_PROGRESS) {
          return (
            <Button size="small" onClick={onContinue}>
              Continue
            </Button>
          );
        }
        if (status === MOCK_TEST_USER_STATUS.FINISHED) {
          return (
            <Button size="small" onClick={onResult}>
              Result
            </Button>
          );
        }
        return null;
      },
    },
  ]);
  const [data, setData] = useState<IMockTestHis[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(mockTestHis);
  }, [mockTestHis]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      scroll={{ x: 800 }}
      bordered
      pagination={false}
      rowKey="id"
    />
  );
};
