import * as S from './ExamMockTestResult.styles';
import { Tabs, TabPane } from '@app/components/common/Tabs/Tabs';
import { useState } from 'react';
import { TableProps, Tag } from 'antd';
import { statusType } from '@app/utils/utils';
import { TableCollapse } from './TableCollapse';

export interface IDataTypeResult {
  key: string;
  type: string;
  marks: number;
  status: string;
}

export const ExamMockTestResult: React.FC = () => {
  const [columns, setColumns] = useState<TableProps<IDataTypeResult>['columns']>([
    {
      title: 'NO',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={statusType[status]} key={status}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Total score',
      dataIndex: 'marks',
      key: 'marks',
    },
  ]);

  const [data, setData] = useState<IDataTypeResult[]>([
    {
      key: '1',
      marks: 8,
      type: 'Speaking',
      status: 'Done',
    },
    {
      key: '2',
      marks: 0,
      type: 'Speaking',
      status: 'NoAnswer',
    },
    {
      key: '3',
      marks: 10,
      type: 'Speaking',
      status: 'Done',
    },
  ]);

  return (
    <S.Wrapper>
      <S.CardHeader>
        <S.CardHeaderLeft>
          <p className="mb-2">
            Email: <b>your@gmail.com</b>
          </p>
          <p className="mb-2">
            Created date: <b>16/12/2024, 07:49:16</b>
          </p>
          <p className="mb-2">
            Exam: <b>MOCK 14 - 2 HOURS</b>
          </p>
        </S.CardHeaderLeft>
        <S.CardHeaderRight>
          <img src="/images/finished.png" width={100} alt="finished" />
        </S.CardHeaderRight>
      </S.CardHeader>
      <Tabs defaultActiveKey="1">
        <TabPane tab={`Speaking`} key="1">
          <TableCollapse columns={columns} dataSource={data} />
        </TabPane>
        <TabPane tab={`Writing`} key="2"></TabPane>
        <TabPane tab={`Reading`} key="3">
          3
        </TabPane>
        <TabPane tab={`Listening`} key="4">
          4
        </TabPane>
      </Tabs>
    </S.Wrapper>
  );
};
