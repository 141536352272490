import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Progress } from '@app/components/common/Progress/Progress';
import { Button } from '@app/components/common/buttons/Button/Button';
import { RecordingIcon } from '@app/components/common/icons/RecordingIcon';
import * as S from './Recording.styles';

interface RecordingProps {
  onComplete: (audioUrl: string) => void;
  recordingTime: number;
}

export const Recording = forwardRef((props: RecordingProps, ref) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);
  const audioChunksRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [micAvailable, setMicAvailable] = useState<boolean>(true);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const checkMicrophone = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasMicrophone = devices.some((device) => device.kind === 'audioinput');
        setMicAvailable(hasMicrophone);
        if (hasMicrophone) {
          requestPermission();
        } else {
          console.error('Microphone device missing');
        }
      } catch (error) {
        console.error('Error checking microphone:', error);
      }
    };

    checkMicrophone();
  }, []);

  const requestPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setPermissionGranted(true);
      startRecording(stream);
    } catch (error) {
      console.error('Lỗi khi yêu cầu quyền truy cập microphone: ', error);
      setPermissionGranted(false);
    }
  };

  const startRecording = (stream: MediaStream) => {
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      audioChunksRef.current = [];
      props.onComplete(audioUrl);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;

    // Đếm ngược thời gian 40 giây
    timerRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds >= props.recordingTime) {
          clearInterval(timerRef.current!);
          mediaRecorder.stop();
          return props.recordingTime;
        }
        return prevSeconds + 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current!);
    }
  };

  useImperativeHandle(ref, () => ({
    stopRecording,
  }));

  if (!micAvailable) {
    return (
      <S.Box>
        <S.TextRed>Microphone device missing. Please connect a microphone. </S.TextRed>
      </S.Box>
    );
  }

  return (
    <S.Box>
      {permissionGranted ? (
        <>
          <p>Recording... {seconds} seconds remaining</p>
          <Progress percent={(seconds / props.recordingTime) * 100} showInfo={false} />
          <Button shape="circle" icon={<RecordingIcon />} onClick={stopRecording} />
          <p>Stop recording</p>
        </>
      ) : (
        <p>Waiting for microphone permission...</p>
      )}
    </S.Box>
  );
});
