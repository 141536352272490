import { useEffect, useRef, useState } from 'react';
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js'; // Import WaveSurferOptions
import { PlayingIcon } from '@app/components/common/icons/PlayingIcon';
import { PauseIcon } from '@app/components/common/icons/PauseIcon';
import * as S from './Waveform.styles';
import { DownloadIcon } from '@app/components/common/icons/DownloadIcon';

interface WaveformProps {
  url: string;
}

const formWaveSurferOptions = (ref: HTMLElement | null): WaveSurferOptions => ({
  container: ref as HTMLElement,
  waveColor: '#333',
  progressColor: 'rgb(51, 156, 253)',
  cursorColor: 'rgb(51, 156, 253)',
  barWidth: 3,
  barRadius: 3,
  height: 20,
  normalize: true,
});

export const Waveform: React.FC<WaveformProps> = ({ url }) => {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(1);

  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on('ready', () => {
      setDuration(wavesurfer.current?.getDuration() || 0);
    });

    wavesurfer.current.on('audioprocess', () => {
      setCurrentTime(wavesurfer.current?.getCurrentTime() || 0);
    });

    wavesurfer.current.on('finish', () => {
      setIsPlaying(false);
      setCurrentTime(0);
    });

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, [url]);

  const togglePlay = () => {
    if (wavesurfer.current) {
      isPlaying ? wavesurfer.current.pause() : wavesurfer.current.play();
      setIsPlaying(!isPlaying);
    }
  };

  const handleSpeedChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSpeed = parseFloat(e.target.value);
    setSpeed(newSpeed);
    if (wavesurfer.current) {
      wavesurfer.current.setPlaybackRate(newSpeed); // Cập nhật tốc độ phát
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div className="flex items-center gap-2">
      <button onClick={togglePlay}>{isPlaying ? <PauseIcon /> : <PlayingIcon />}</button>
      <div className="w-full" ref={waveformRef} />

      <S.Duration>{`${formatTime(currentTime)} / ${formatTime(duration)}`}</S.Duration>

      <S.StyledSelect value={speed} onChange={handleSpeedChange}>
        <option value={0.5}>0.5x</option>
        <option value={1}>1x</option>
        <option value={1.5}>1.5x</option>
        <option value={2}>2x</option>
      </S.StyledSelect>
      <S.Download to={url} download>
        <DownloadIcon />
      </S.Download>
    </div>
  );
};
