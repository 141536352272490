import styled, { css } from 'styled-components';

interface DrawerProps {
  open: boolean;
}

export const Drawer = styled.div<DrawerProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 100%;
  background-color: #fff;
  box-shadow: ${({ open }) => (open ? '0 0 15px rgba(0, 0, 0, 0.3)' : 'none')};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  z-index: 1000;
`;

export const BtnClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  padding: 8px;
  align-self: flex-end;

  &:hover {
    color: #ff0000;
  }
`;

export const Backdrop = styled.div<DrawerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease;
  z-index: 999;
`;

export const DrawerContent = styled.div`
  color: #333;
`;

export const ASQ = styled.span`
  background: #9fa2ae;
  padding: 0.5rem;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
`;

export const BoxTabs = styled.div`
  display: flex;
  justify-content: between;
  justify-content: space-between;
  align-items: center;
`;

export const Tabs = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 24px 0px;
`;

interface TabItemProps {
  active?: boolean;
}

export const TabItem = styled.div<TabItemProps>`
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  padding: 6px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    `}
`;

export const ListAnswer = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding-right: 8px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px; /* Bo góc */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

interface StyledItemAnswerProps {
  isCorrect: boolean;
}

export const ItemAnswer = styled.div<StyledItemAnswerProps>`
  display: flex;
  border-bottom: 1px solid #cccccc;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.isCorrect ? 'rgb(48, 60, 108)' : '')};
  color: ${(props) => (props.isCorrect ? '#FFF' : '#000')};
`;
export const MainContent = styled.div`
  flex: 9;
  padding: 8px 0px;
  font-size: 14px;
  font-weight: 600;

  span {
    padding-right: 12px;
  }
`;
export const SideContent = styled.div`
  flex: 1;
`;

export const Level = styled.div<{ difficulty: string }>`
  color: #ffffff;
  padding: 2px 8px;
  font-weight: 700;
  line-height: 23px;
  border-radius: 92px;
  text-transform: capitalize;
  font-size: 14px;

  background-color: ${({ difficulty }) =>
    difficulty === 'easy'
      ? 'rgb(39, 194, 129)'
      : difficulty === 'medium'
      ? 'rgb(33, 81, 236)'
      : difficulty === 'hard'
      ? 'rgb(209, 54, 54)'
      : 'gray'};
`;

export const Check = styled.div`
  border-radius: 50%;
  background-color: var(--success-color);
`;

export const FormInput = styled.div`
  width: 100%;
  border: 1px solid black;
  display: flex;
  box-shadow: none;
  align-items: center;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  padding: 15px;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 1.1876em;
  margin: 0;
  display: block;
  font-weight: 400;
  font-size: 1rem;
  outline: none;
`;
