import React, { useEffect, useState } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';

interface CountdownTimerProps {
  onTimeout: () => void;
  initialTime: number;
  onGetTime?: (time: number) => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ onTimeout, initialTime, onGetTime }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    setTimeLeft(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (onGetTime) onGetTime(timeLeft);
  }, [timeLeft, onGetTime]);

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            onTimeout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft, onTimeout]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div className="flex items-center space-x-2">
      <ClockCircleOutlined className="text-base" />
      <p className="text-left text-sm font-medium">TIME REMAINING {formatTime(timeLeft)}</p>
    </div>
  );
};

export default CountdownTimer;
