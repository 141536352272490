import { useState } from 'react';
import { Tag, TableProps } from 'antd';
import { TableCollapse } from '@app/components/common/TableCollapse';
import * as S from './MockTestDetail.style';
import { Tabs, TabPane } from '@app/components/common/Tabs/Tabs';
import { IDataType } from '@app/interfaces/interfaces';

export const styles: { [key: string]: string } = {
  Pending: 'warning',
  Done: 'success',
  Failed: 'error',
  Cancelled: 'default',
};

export const MockTestDetailPage: React.FC = () => {
  const [columns, setColumns] = useState<TableProps<IDataType>['columns']>([
    {
      title: 'Id',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={styles[status]} key={status}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Total Score',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
  ]);
  const [data, setData] = useState<IDataType[]>([
    {
      key: '1',
      name: 'John Doe',
      total: 100,
      type: 'Speaking',
      question: 'What is 2+2?',
      answer: '4',
      marks: 5,
      status: 'Pending',
    },
    {
      key: '2',
      name: 'Jane Smith',
      total: 100,
      type: 'Speaking',
      question: 'What is the capital of France?',
      answer: 'Paris',
      marks: 10,
      status: 'Done',
    },
  ]);
  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Mock Test</S.Title>
      <S.Box>
        <div>
          <p>
            Email: <span className="font-bold">abcd@gmail.com</span>
          </p>
          <p>
            Exam: <span className="font-bold">MOCK TEST 1</span>
          </p>
          <p>
            Created date: <span className="font-bold">2021-09-09</span>
          </p>
        </div>
        <div>
          <img src="/images/completed-stamp.png" width={150} alt="completed" />
        </div>
      </S.Box>

      <Tabs defaultActiveKey="1">
        <TabPane tab={`Speaking`} key="1">
          <TableCollapse columns={columns} dataSource={data} />
        </TabPane>
        <TabPane tab={`Writing`} key="2">
          <TableCollapse columns={columns} dataSource={data} />
        </TabPane>
        <TabPane tab={`Reading`} key="3">
          3
        </TabPane>
        <TabPane tab={`Listening`} key="4">
          4
        </TabPane>
      </Tabs>
    </div>
  );
};
