import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as S from './ExamMockTestBreak.styles';

interface ExamMockTestBreakProps {
  onSkip: () => void;
}

export const ExamMockTestBreak: React.FC<ExamMockTestBreakProps> = ({ onSkip }) => {
  return (
    <>
      <S.Wrapper>
        <img src="/images/mock_test_resume.gif" alt="mock_test_resume" />
        <p className="text-2xl font-bold">Resting Between Tests</p>
      </S.Wrapper>
      <S.BtnFooter>
        <Button type="primary" onClick={onSkip}>
          Skip
        </Button>
      </S.BtnFooter>
    </>
  );
};
