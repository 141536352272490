import { getDetailExam, getExams, IExam } from '@app/api/academic.api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk để lấy danh sách câu hỏi (Phân trang)
export const fetchExamList = createAsyncThunk(
  'exam/fetchExamList',
  async ({ page = 1, type, searchCode }: { page: number; type: string; searchCode: string }) => {
    const response = await getExams(page, 20, type, searchCode);
    return response;
  },
);

// Thunk để lấy chi tiết câu hỏi
export const fetchExamDetail = createAsyncThunk('exam/fetchExamDetail', async (id: string) => {
  const response = await getDetailExam(id);
  return response;
});

interface ErrorType {
  message: string;
  name?: string;
  stack?: string;
}

interface ExamState {
  listExam: IExam[];
  currentQuestion: IExam | null;
  currentIndex: number;
  page: number;
  totalPages: number;
  itemCount: number;
  loadingList: boolean;
  loadingDetail: boolean;
  error: ErrorType | null;
  selectedOptions: Record<string, string>;
  searchCode: string;
}

const initialState: ExamState = {
  listExam: [],
  currentQuestion: null,
  currentIndex: 0,
  page: 1,
  totalPages: 1,
  itemCount: 0,
  loadingList: false,
  loadingDetail: false,
  error: null,
  selectedOptions: {},
  searchCode: '',
};

export const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    clearCurrentQuestion: (state) => {
      state.currentQuestion = null;
    },
    setSearch: (state, action) => {
      state.searchCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Exam List
      .addCase(fetchExamList.pending, (state) => {
        state.loadingList = true;
      })
      .addCase(fetchExamList.fulfilled, (state, action) => {
        state.loadingList = false;
        state.listExam = action.payload.data;
        state.itemCount = action.payload.meta.itemCount;
        state.totalPages = action.payload.meta.pageCount;
        state.page = action.payload.meta.page;
      })
      .addCase(fetchExamList.rejected, (state, action) => {
        state.loadingList = false;
        console.log(action.error);
        const error = action.error as ErrorType;
        state.error = error;
      })

      // Fetch Exam Detail
      .addCase(fetchExamDetail.pending, (state) => {
        state.loadingDetail = true;
      })
      .addCase(fetchExamDetail.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.currentQuestion = action.payload;
        const index = state.listExam.findIndex((item) => item.id === state.currentQuestion?.id);
        state.currentIndex = index;
      });
  },
});

export const { setPage, clearCurrentQuestion, setSearch } = examSlice.actions;
export default examSlice.reducer;
