import styled from 'styled-components';

export const Card = styled.div`
  gap: 1px;
  width: 100%;
  display: grid;
  grid-template-columns: 150px 1fr;
`;

export const Box = styled.div`
  outline: #dee1ed solid 1px;
  font-size: 14px;
  padding: 1rem;

  &:first-child {
    border-top-left-radius: 8px;
  }
  &:nth-child(2) {
    border-top-right-radius: 8px;
  }
  &:nth-last-child(2) {
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
  }
`;

export const TableScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ColScore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const HeaderScore = styled.div`
  width: 100%;
  padding: 8px 14px;
  text-align: center;
  background: #f1f4f9;
`;
