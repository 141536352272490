import React from 'react';
import { convertQuestionByType } from '@app/utils/utils';
import * as S from './QuestionHeader.styles';
import { ReactComponent as BookmarkIcon } from '@app/assets/icons/bookmark-icon.svg';

interface IQuestionHeaderProps {
  orderId: string;
  difficulty: string;
  type: string;
}

export const QuestionHeader: React.FC<IQuestionHeaderProps> = React.memo(({ orderId, difficulty, type }) => {
  return (
    <S.Wrapper>
      <div>
        <S.QuestionNum>{orderId}</S.QuestionNum>
      </div>
      <div className="text-base font-semibold">{convertQuestionByType(type)}</div>
      <div className="flex gap-4">
        <S.Tag difficulty={difficulty}>{difficulty}</S.Tag>
        <div className="cursor-pointer">
          <BookmarkIcon />
        </div>
      </div>
    </S.Wrapper>
  );
});
