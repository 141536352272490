import { useEffect, useRef, useState } from 'react';
import { STAGES } from '@app/constants/stages';
import * as S from './SpeakingRepeatSentence.styles';
import { Recording } from '../Recording';
import { InfoModal } from '@app/components/common/Modal/Modal';
import { Completed } from '../Completed';
import { Answer } from '../../Answer';
import { AudioPlayer } from '@app/components/exam/AudioPlayer';
import { useLocation } from 'react-router-dom';
import useCountdownTimer from '@app/hooks/useCountdownTimer';

// const gradingContent = 'Please write your name on essay cover sheet .';

interface SpeakingRepeatSentenceProps {
  prepTime: number;
  recordingTime: number;
  audioQuestion: string;
  showAnswer: boolean;
  questionNumber: string;
  isFirstRender: React.MutableRefObject<boolean>;
  gradingContent: string;
  onAnswerChange?: (audio: Blob) => void;
}

export const SpeakingRepeatSentence: React.FC<SpeakingRepeatSentenceProps> = ({
  prepTime,
  recordingTime,
  audioQuestion,
  showAnswer,
  questionNumber,
  isFirstRender,
  gradingContent,
  onAnswerChange,
}) => {
  const recordingRef = useRef<{ stopRecording: () => void }>(null);
  const [stage, setStage] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audioURL, setAudioURL] = useState('');

  const { seconds, start, reset } = useCountdownTimer({
    initialSeconds: prepTime,
    onComplete: () => {
      setStage(STAGES.PLAYING);
      setIsPlaying(true);
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      showPermissionModal();
    } else {
      initialize();
    }
  }, [questionNumber]);

  useEffect(() => {
    if (!showAnswer) return;
    if (stage == STAGES.RECORDING) {
      handleStopRecording();
    } else {
      setStage(STAGES.COMPLETE);
    }
  }, [showAnswer]);

  const showPermissionModal = () => {
    InfoModal({
      title: 'Additional permissions',
      content: 'Click the OK button to enable record audio.',
      onOk: () => {
        initialize();
      },
    });
  };

  const initialize = () => {
    setIsPlaying(false);
    setStage(null);
    reset();

    start();
    setStage(STAGES.START);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    if (stage === STAGES.PLAYING) setStage(STAGES.RECORDING);
  };

  const handleComplete = async (audio: string) => {
    setStage(STAGES.COMPLETE);
    setAudioURL(audio);

    const blob = await fetch(audio).then((res) => res.blob());
    onAnswerChange && onAnswerChange(blob);
  };

  const handleStopRecording = () => {
    recordingRef.current?.stopRecording();
  };

  return (
    <>
      <S.Wrapper>
        {stage === STAGES.START && (
          <p>
            Beginning in <S.TextRed>{seconds}</S.TextRed> seconds
          </p>
        )}
        {(stage === STAGES.PLAYING || stage === STAGES.RECORDING) && <p>Status: playing...</p>}
        {stage === STAGES.COMPLETE && <p>Status: Completed</p>}
        <AudioPlayer audioQuestion={audioQuestion} onEnded={handleEnded} startPlaying={isPlaying} />
      </S.Wrapper>

      {stage === STAGES.RECORDING && (
        <div className="mt-5">
          <Recording ref={recordingRef} recordingTime={recordingTime} onComplete={handleComplete} />
        </div>
      )}

      {stage === STAGES.COMPLETE && (
        <div className="mt-5">
          <Completed />
        </div>
      )}

      <div className="mt-5">
        {/* {!showAnswer && (stage == STAGES.RECORDING || stage == STAGES.COMPLETE) && (
                    <Button type="primary" onClick={handleShowAnswer}>
                        Answer
                    </Button>
                )} */}
        {showAnswer && (
          <Answer
            showAnswer={showAnswer}
            audioURL={audioURL}
            showNote={true}
            gradingContent={gradingContent}
            audioQuestion={audioQuestion}
          />
        )}
      </div>
    </>
  );
};
