import styled from 'styled-components';

export const Header = styled.div`
  background: #fae7a5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  background: #fff;
  color: #333;
  padding: 25px 20px;
  max-width: 72rem;
  margin: 2rem auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
`;
