import { useEffect, useState } from 'react';
import * as S from './ReadingMCQRSingleAnswer.styles';
import { Radio } from '@app/components/common/Radio/Radio';
import { Button } from '@app/components/common/buttons/Button/Button';
import { CloseIcon } from '@app/components/common/icons/CloseIcon';
import { CheckSuccessIcon } from '@app/components/common/icons/CheckSuccessIcon';

interface Props {
  showAnswer: boolean;
  text: string;
  description: string;
  initialChoicesString: string;
  answer: string;
  onAnswerChange?: (answer: string) => void;
}
export const ReadingMCQRSingleAnswer: React.FC<Props> = ({
  showAnswer,
  text,
  description,
  initialChoicesString,
  answer,
  onAnswerChange,
}) => {
  const [choices, setChoices] = useState<{ label: string; text: string }[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

  useEffect(() => {
    setSelectedAnswer(null);
    if (initialChoicesString) {
      const parsedChoices = JSON.parse(initialChoicesString);
      setChoices(parsedChoices);
    }
  }, [text]);

  useEffect(() => {
    onAnswerChange && onAnswerChange(selectedAnswer || '');
  }, [selectedAnswer]);

  const handleRadioChange = (value: string) => {
    setSelectedAnswer(value);
  };

  return (
    <>
      <div className="text-base font-medium">{text}</div>

      <S.ContentQuestion>{description}</S.ContentQuestion>

      <S.RadioGroupStyle>
        {choices.map((item: any) => (
          <div key={item.label} className="flex gap-2 items-center">
            {showAnswer && (
              <span
                style={{
                  width: '1em',
                  fontSize: '1rem',
                  color: answer === item.label ? 'green' : 'red',
                }}
              >
                {answer === item.label ? <CheckSuccessIcon /> : <CloseIcon />}
              </span>
            )}

            <Radio
              value={item.label}
              checked={selectedAnswer === item.label}
              onChange={() => handleRadioChange(item.label)}
            >
              {item.text}
            </Radio>
          </div>
        ))}
      </S.RadioGroupStyle>
    </>
  );
};
