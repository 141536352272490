import { useNavigate } from 'react-router-dom';
import { Table } from 'components/common/Table/Table';
import * as S from './ResultPage.styles';
import { useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { EyeOutlined } from '@ant-design/icons';
import { statusType } from '@app/utils/utils';
import { Tag } from 'antd';

export const ResultPage: React.FC = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    { title: '#', dataIndex: 'code' },
    { title: 'Mock Name', dataIndex: 'name' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag color={statusType[status]} key={status}>
          {status}
        </Tag>
      ),
    },
    { title: 'Speaking', dataIndex: 'speaking' },
    { title: 'Writing', dataIndex: 'writing' },
    { title: 'Reading', dataIndex: 'reading' },
    { title: 'Listening', dataIndex: 'listening' },
    { title: 'Score', dataIndex: 'score' },
    { title: 'CreatedDate', dataIndex: 'date' },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <Button
            type="primary"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record.key)}
          ></Button>
        );
      },
    },
  ]);
  const [data, setData] = useState([
    {
      key: 1,
      code: 1,
      name: 'PTE Mock Test 1',
      status: 'Done',
      speaking: 10,
      writing: 10,
      reading: 10,
      listening: 10,
      score: 40,
      date: '2024',
    },
    {
      key: 2,
      code: 2,
      name: 'PTE Mock Test 2',
      status: 'Pending',
      speaking: 10,
      writing: 10,
      reading: 10,
      listening: 10,
      score: 40,
      date: '2024',
    },
  ]);
  const [loading, setLoading] = useState(false);

  const handleViewDetails = (id: number) => {
    navigate(`/result/${id}`);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-8">
      <S.Title level={3}>Result</S.Title>
      <div>
        <p className="mt-2 text-sm">This is the result page. Here you can see the result of the test.</p>

        <Table columns={columns} dataSource={data} loading={loading} scroll={{ x: 800 }} bordered />
      </div>
    </div>
  );
};
