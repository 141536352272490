import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import styled from 'styled-components';

export const Title = styled(Typography.Title)`
  &.ant-typography {
    position: relative;
    color: var(--text-dark-color);
    font-size: ${FONT_SIZE.md};
    padding-left: 10px;

    &:before {
      content: '';
      width: 32px;
      height: 4px;
      padding-right: 8px;
      background-color: #fecc65;
      position: absolute;
      -webkit-transform: rotate(90deg) translate(20px, 25px);
      transform: rotate(90deg) translate(20px, 25px);
      border-radius: 12px;
    }

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.xxxxl};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Column = styled.div`
  flex: 1;
`;

export const Ul = styled.ul`
  display: grid;
  padding-inline-start: 20px;
  grid-template-columns: 1fr 1fr;
  list-style-type: disc;
  margin-bottom: 1rem;

  li {
    color: #757783;
  }
`;
