import { Button } from 'antd';
import * as S from './Description.styles';

interface DescriptionProps {
  gotoPrevious: () => void;
  gotoNext: () => void;
  gotoDashboard: () => void;
}

export const Description: React.FC<DescriptionProps> = ({ gotoPrevious, gotoDashboard, gotoNext }) => {
  return (
    <S.Wrapper>
      <S.Content>
        Read the prompt below. In 25 seconds, you must reply in your own words, as naturally and clearly as possible.
        You have 30 seconds to record your response. Your response will be sent together with your score report to the
        institutions by you.
      </S.Content>
      <div>
        <p>Please introduce yourself. For example, you could talk about one of the following.</p>
        <p>- Your interests</p>
        <p>- Your plans for future study</p>
        <p>- Why you want to study abroad</p>
        <p>- Why you need to learn English</p>
        <p>- Why you chose this test</p>
      </div>

      <S.Footer>
        <S.Column>
          <Button type="default" onClick={gotoDashboard}>
            Return to Dashboard
          </Button>
        </S.Column>
        <S.Column className="flex justify-end gap-3">
          <Button type="default" onClick={gotoPrevious}>
            Previous
          </Button>
          <Button type="primary" onClick={gotoNext}>
            Next
          </Button>
        </S.Column>
      </S.Footer>
    </S.Wrapper>
  );
};
